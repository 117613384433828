import isEmpty from "is-empty";
import React, { useCallback, useEffect, useState } from "react";
import {
  FloatingLabel,
  Form,
  Table,
  Row,
  Col,
  FormSelect,
} from "react-bootstrap";
import { useParams } from "react-router";
import { ConfirmModal } from "../Components/Modal/ConfirmModal";
import { CustomModal } from "../Components/Modal/CustomModal";
import { ToastMessage } from "../Components/Toast";
import Pagination from "react-js-pagination";
import axios from "axios";
import { FaPen } from "react-icons/fa";

import {
  allMarkets,
  marketBetEnableDisabled,
  marketEnableDisabled,
  matchBetEnableDisabled,
  matchEnableDisabled,
  setMarketMaxMinBet,
  setMatchMaxMinBet,
  setSeriesMaxMinBet,
  settleMarket,
  settleMarketFancy,
  updateRunnerOdds,
  settleMarketToss,
  getDifferenceAPI,
  changeOddDifferenceAPI,
} from "./Service";
import { useAllMarket } from "../Context/AllMarketProvider";
import moment from "moment/moment";
import { autoSettleFor } from "../utils/config";
import toast from "react-hot-toast";

const marketNameTemp = "MATCH_ODDS";
const marketNameWhoWin = "Will Win the Toss";

export default function AllMarketDataComponent(props) {
  const { eventId, matchId, matchName } = useParams();
  const { allMarketData, onSubmitAllMarketData } = useAllMarket();
  const [isSeriesSetPrice, setIsSeriesSetPrice] = useState(false);
  const [showConfrimModal, setShowConfirmModal] = useState(false);
  const [isMatchSetPrice, setIsMatchSetPrice] = useState(false);
  const [editBetData, setEditBetData] = useState({});
  const [showEditBetModal, setShowEditBetModal] = useState(false);
  const [isMarketBetLockStatus, setIsMarketBetLockStatus] = useState(false);
  const [isMatchBetLockStatus, setIsMatchBetLockStatus] = useState(false);
  const [isMarketEnableStatus, setIsMarketEnableStatus] = useState(false);
  const [isBetLockStatus, setIsBetLockStatus] = useState(false);
  const [showConfrimMatchModal, setShowConfirmMatchModal] = useState(false);
  const [confirmMatchDetails, setConfirmMatchDetails] = useState("");
  const [showMarketSettelModal, setShowMarketSettelModal] = useState(false);
  const [settelmentData, setSettelmentData] = useState({});
  const [marketSettlmentType, setMarketSettlmentType] = useState("");
  const [selectedMatchId, setSelectedMatchId] = useState("");
  const [errorMessage, setErroreMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [winnerData, setWinnerData] = useState({});
  const [matchRestult, setMatchResult] = useState("");
  const [isSessionPassed, setIsSessionPassed] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);

  const [matchResultType, setMatchResultType] = useState({
    abandoned: false,
    tie: false,
    finalResult: false,
  });
  const [finalResult, setFinalResult] = useState(0);
  const [showOddsDetails, setShowOddsDetails] = useState(false);
  const [oddsDetails, setOddsDetails] = useState({});
  const [oddDifference, setOddDifference] = useState({});
  const handleCloseOddsDetailsModal = () => setShowOddsDetails(false);
  const handleOpenOddsDetailsModal = (data) => {
    data.subMarketInfo.forEach((el) => {
      el.isEdit = true;
    });
    setOddsDetails(data);
    setShowOddsDetails(true);
  };

  const handleCloseEditBetModal = () => setShowEditBetModal(false);
  const handleCloseConfirmMatchModal = () => setShowConfirmMatchModal(false);
  const handleOpenConfirmMatachModal = () => setShowConfirmMatchModal(true);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChangeOddsDetails = (e, key, itemId) => {
    const { value } = e.target;

    const tempOdds = [...oddsDetails.subMarketInfo];

    tempOdds.forEach((el) => {
      if (el.id === itemId) {
        el.runnerOdds[key] = parseFloat(value);
      }
    });

    setOddsDetails((prev) => {
      return { ...prev, subMarketInfo: tempOdds };
    });
  };

  const handleChangeRadio = (e, type) => {
    const { name, checked } = e.target;
    setMatchResultType({ [type]: checked });
  };

  const handleChangeFinalResult = (e) => {
    setFinalResult(e.target.value);
  };

  const handleOpenEditBetModal = (id, type) => {
    setIsSeriesSetPrice(false);
    setIsMatchSetPrice(false);
    setEditBetData((prevState) => ({ ...prevState, marketId: id }));

    setShowEditBetModal(true);
  };
  const handleCloseToast = () => setShowToast(false);
  const handleShowToast = () => setShowToast(true);
  const handleCloseMarketSettelModal = () => setShowMarketSettelModal(false);
  const handleOpenMarketSettelModal = (data, eventId, type) => {
    setShowMarketSettelModal(true);
    setSettelmentData((prev) => ({ ...prev, ...data, eventId }));
    setMarketSettlmentType(type);
    setSelectedMatchId(matchId);
  };

  const allMarketsHandler = useCallback(async () => {
    const res = await allMarkets(matchId, +activePage - 1);

    if (res.code === 200) {
      onSubmitAllMarketData(res.response);
      setTotalPageCount(res?.response?.inactive?.totalElements);
    }
  }, [activePage, matchId]);

  useEffect(() => {
    allMarketsHandler();
  }, [allMarketsHandler, activePage]);

  const handleSwitchChange = async (e, data, type) => {
    e.stopPropagation();

    //let res = await marketBetEnableDisabled(data.marketName, e.target.checked);
    if (type === "Market_BetStatus") {
      setIsMarketBetLockStatus(true);
      setIsMatchBetLockStatus(false);
      setIsMarketEnableStatus(false);
      setIsBetLockStatus(false);
      confirmStatusUpdate(data, "MARKET");
      handleOpenConfirmMatachModal();
    }
    if (type === "Market_Enable") {
      const res = await marketEnableDisabled(data.id, !data.enabled);
      if (res) {
        allMarketsHandler();
      }
    }
  };

  const confirmStatusUpdate = (data, type) => {
    if (type === "MARKET") {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled Bet" : "Disabled Bet",
        matchName: data.eventName,
        marketId: data.id,
        betEnable: !data.betLock,
      }));
    }
  };

  const matchEnableDisabledHandler = async () => {
    const { isEnabled, matchId, betEnable, marketId } = confirmMatchDetails;

    let res;
    if (isMatchBetLockStatus) {
      res = await matchBetEnableDisabled(matchId, betEnable);
    } else if (isMarketBetLockStatus) {
      res = await marketBetEnableDisabled(marketId, betEnable);
    } else {
      res = await matchEnableDisabled(matchId, isEnabled);
    }

    handleCloseConfirmMatchModal();
    allMarketsHandler();
  };

  const onSubmitBetHandler = async () => {
    const { maxMarket, minMarket, marketId, seriesId, matchId } = editBetData;
    if (parseInt(minMarket) > parseInt(maxMarket)) {
      setErroreMessage("Invalid input!");
      return;
    } else {
      setErroreMessage("");
    }
    let res;

    if (isSeriesSetPrice) {
      res = await setSeriesMaxMinBet(maxMarket, minMarket, seriesId);
    } else if (isMatchSetPrice) {
      res = await setMatchMaxMinBet(maxMarket, minMarket, matchId);
    } else {
      res = await setMarketMaxMinBet(maxMarket, minMarket, marketId);
    }

    if (res.code === 200) {
      handleShowToast();
      allMarketsHandler();
      setEditBetData({});
      toast.success("Action completed successfully!");
      handleCloseEditBetModal();
    }
  };

  const handleMarketEditChange = (e) => {
    const { name, value } = e.target;
    setErroreMessage("");

    setEditBetData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const settleMarketHandler = async () => {
    const { marketName, eventId, marketId, comment } = settelmentData;
    const { selectionId, winnerName } = winnerData;
    const payload = {
      eventId: settelmentData.cricketEventId,
      marketId: settelmentData.id,
      marketName,
      settlementComment: comment,
      winnerName: winnerName,
      winnerSelectionId: selectionId,
      eventType: marketSettlmentType,
      isSessionPassed: true,
      abandoned: false,
      tie: false,
    };

    if (!isEmpty(matchRestult)) {
      payload[matchRestult] = true;
      delete payload.winnerName;
    }
    if (settelmentData.marketName === "Who Will Win Toss") {
    }
    let res;
    if (
      settelmentData.marketName === "MATCH_ODDS-BookMaker 0% Comm.-M" ||
      settelmentData.marketName === "MATCH_ODDS" ||
      settelmentData.marketName === "BOOKMAKER_ODDS_1" ||
      settelmentData.marketName === "BOOKMAKER_ODDS_2"
    ) {
      res = await settleMarket(payload);
    } else if (
      settelmentData.marketName
        .toLowerCase()
        .includes(marketNameWhoWin.toLowerCase())
    ) {
      res = await settleMarketToss(payload);
    }

    if (res?.code === 200) {
      toast.success("Market Settled Successfully");
      allMarketsHandler();
    }
    if (!isEmpty(res?.response)) {
      handleShowToast();
      return setToastMessage(res?.response.data);
    }
    handleCloseMarketSettelModal();
    setSettelmentData({});
    setSettelmentData({});
    setIsSessionPassed(false);
    const tempData = [...allMarketData];

    for (const data of tempData) {
      if (data.id === settelmentData.id) {
        data.isMarketSettled = !data.isMarketSettled;
      }
    }
    onSubmitAllMarketData(tempData);
  };

  const handleChangeWinner = (e) => {
    const { value } = e.target;
    console.log(value);
    if (value === "abandoned" || value === "tie") {
      return setMatchResult(value);
    } else if (value === "YES" || value === "NO") {
      setMatchResult(value);
      return;
    } else {
      setMatchResult("");
    }
    const selectionId = value.split("-")[0];
    var winnerName = value.split("-")[1];
    if (value.split("-").length > 2) {
      winnerName = value.split("-").slice(1).join("-");
    }
    setWinnerData((prev) => ({
      ...prev,
      selectionId: selectionId,
      winnerName,
    }));
  };

  const handleSwitchSettelChange = (e) => {
    e.stopPropagation();
    setIsSessionPassed(e.target.checked);
  };

  const handleChangeSettel = (e) => {
    const { name, value } = e.target;
    setSettelmentData((prevState) => ({ ...prevState, [name]: value }));
  };

  const settleMarketFancyHandler = async () => {
    if (
      settelmentData.marketName === "MATCH_ODDS-BookMaker 0% Comm.-M" ||
      settelmentData.marketName === "MATCH_ODDS" ||
      settelmentData.marketName === "BOOKMAKER_ODDS_1" ||
      settelmentData.marketName === "BOOKMAKER_ODDS_2"
    ) {
      return settleMarketHandler();
    }
    const payload = {
      abandoned: matchResultType.abandoned ? true : false,
      eventId: settelmentData.cricketEventId,
      eventType: marketSettlmentType,
      marketId: settelmentData.id,
      marketName: settelmentData.marketName,
      settlementComment: settelmentData.comment,
      tie: matchResultType.tie ? true : false,
    };

    if (matchResultType.finalResult) {
      payload.result = finalResult;
    }
    if (marketSettlmentType === "rollback") {
      payload.abandoned = false;
      payload.tie = false;
      payload.result = null;
    }
    if (settelmentData?.gameType == "oddeven") {
      payload.result = finalResult ? "YES" : "NO";
      delete payload.finalScore;
    } else {
      if (settelmentData?.requiresNumberForSettlement == false) {
        payload.result = finalResult;
      }
    }
    if (
      settelmentData?.marketName
        ?.toLowerCase()
        .includes(marketNameWhoWin.toLowerCase())
    ) {
      payload.result = matchRestult;
    }
    const res = await settleMarketFancy(payload);
    handleCloseMarketSettelModal();
    handleShowToast();
    setToastMessage(res.message);
    setSettelmentData({});
    setSettelmentData({});
    setIsSessionPassed(false);
    if (res.code === 200) {
      setMatchResultType({
        abandoned: false,
        tie: false,
        finalResult: false,
      });
      toast.success("Market Settled Successfully");
      allMarketsHandler();
    }
    const tempData = [...allMarketData];
    for (const data of tempData) {
      if (data.id === settelmentData.id) {
        data.isMarketSettled = !data.isMarketSettled;
      }
    }
    onSubmitAllMarketData(tempData);
  };

  const editOddsHandler = (id) => {
    const tempOdds = [...oddsDetails.subMarketInfo];
    tempOdds.forEach((el) => {
      if (el.id === id) {
        el.isEdit = !el.isEdit;
      }
    });
    setOddsDetails((prev) => {
      return { ...prev, subMarketInfo: tempOdds };
    });
  };

  const onSubmitEditOddsHandler = async (id) => {
    const tempOdds = [...oddsDetails.subMarketInfo];
    const findOdds = tempOdds.find((o) => !o.isEdit);
    const res = await updateRunnerOdds(
      findOdds.runnerOdds,
      findOdds.subMarketId
    );
    if (res.code === 200) {
      handleCloseOddsDetailsModal();
      handleShowToast();
      toast.success("Odds Updated Successfully");
    }
  };

  const getOddDifference = async () => {
    const { response, code } = await getDifferenceAPI(matchId);
    if (code === 200) {
      setOddDifference(response.difference);
    } else {
      setOddDifference(0);
    }
  };
  const setOddDifferenceHandler = async () => {
    const { response, code } = await changeOddDifferenceAPI(
      matchId,
      oddDifference
    );
    if (code === 200) {
      toast.success("Odd Difference Updated Successfully");
    }
  };
  useEffect(() => {
    getOddDifference();
  }, [matchId]);

  return (
    <>
      <div class="page-heading">
        <h3>{matchName}</h3>
      </div>
      <Table striped bordered variant="dark">
        <thead>
          <tr>
            <th>Market Name</th>
            <th>Difference</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>BOOKMAKER_ODDS_1</td>
            <td>
              <div className="row">
                <input
                  className="form-control"
                  placeholder="Enter Difference Here"
                  style={{ marginRight: 10, marginLeft: 10, width: 300 }}
                  value={oddDifference}
                  onChange={(e) => setOddDifference(e.target.value)}
                />
                <button
                  style={{ width: 200 }}
                  onClick={setOddDifferenceHandler}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
      <Table striped bordered variant="dark">
        <thead>
          <tr>
            <th>Active Session</th>
            <th>Stake Price</th>
            <th>Bet Status</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {allMarketData?.active
            ?.filter((item) => !item.marketSettled)
            ?.map((market) => (
              <tr key={market.id}>
                <td>
                  <div
                    style={{
                      backgroundColor: autoSettleFor.find(
                        (item) =>
                          item.toLowerCase() == market?.gameType?.toLowerCase()
                      )
                        ? "green"
                        : "",
                    }}
                    className="d-flex justify-content-between align-items-center "
                  >
                    {market.marketName}

                    {market.marketName === "Who Will Win Toss" && (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleOpenOddsDetailsModal(market)}
                      >
                        View Odds
                      </button>
                    )}
                  </div>
                </td>
                <td
                  title="Edit"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenEditBetModal(market.id)}
                >
                  {market.minStack} - {market.maxStack}
                </td>
                <td>
                  {" "}
                  <Form.Check
                    type="switch"
                    label={market.betLock ? "Bet Disabled" : "Bet Enabled"}
                    checked={market.betLock}
                    onChange={(e) =>
                      handleSwitchChange(e, market, "Market_BetStatus")
                    }
                  />
                </td>
                <td>
                  <Form.Check
                    type="switch"
                    label={market.enabled ? "Enable" : "Disable"}
                    onChange={(e) =>
                      handleSwitchChange(e, market, "Market_Enable")
                    }
                    checked={market.enabled}
                  />
                </td>
                <td>
                  {isEmpty(market.marketSettled) || !market.marketSettled ? (
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        handleOpenMarketSettelModal(
                          market,
                          market.cricketEventId,
                          "settle"
                        )
                      }
                    >
                      Settle
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-danger"
                      onClick={() =>
                        handleOpenMarketSettelModal(
                          market,
                          market.cricketEventId,
                          "rollback"
                        )
                      }
                    >
                      Roll Back
                    </button>
                  )}
                </td>
              </tr>
            ))}
          {allMarketData?.active
            ?.filter((item) => item.marketSettled)
            ?.map((market) => (
              <tr key={market.id}>
                <td>
                  <div
                    style={{
                      backgroundColor: autoSettleFor.find(
                        (item) =>
                          item.toLowerCase() == market?.gameType?.toLowerCase()
                      )
                        ? "green"
                        : "",
                    }}
                    className="d-flex justify-content-between align-items-center "
                  >
                    {market.marketName}

                    {market.marketName === "Who Will Win Toss" && (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleOpenOddsDetailsModal(market)}
                      >
                        View Odds
                      </button>
                    )}
                  </div>
                </td>
                <td
                  title="Edit"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenEditBetModal(market.id)}
                >
                  {market.minStack} - {market.maxStack}
                </td>
                <td>
                  {" "}
                  <Form.Check
                    type="switch"
                    label={market.betLock ? "Bet Disabled" : "Bet Enabled"}
                    checked={market.betLock}
                    onChange={(e) =>
                      handleSwitchChange(e, market, "Market_BetStatus")
                    }
                  />
                </td>
                <td>
                  <Form.Check
                    type="switch"
                    label={market.enabled ? "Enable" : "Disable"}
                    onChange={(e) =>
                      handleSwitchChange(e, market, "Market_Enable")
                    }
                    checked={market.enabled}
                  />
                </td>
                <td>
                  {isEmpty(market.marketSettled) || !market.marketSettled ? (
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        handleOpenMarketSettelModal(
                          market,
                          market.cricketEventId,
                          "settle"
                        )
                      }
                    >
                      Settle
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-danger"
                      onClick={() =>
                        handleOpenMarketSettelModal(
                          market,
                          market.cricketEventId,
                          "rollback"
                        )
                      }
                    >
                      Roll Back
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Table striped bordered variant="dark">
        <thead>
          <tr>
            <th>Market Name</th>
            <th>Stake Price</th>
            <th>Bet Status</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {allMarketData?.inactive?.content
            ? allMarketData?.inactive?.content?.map((market) => (
                <tr key={market.id}>
                  <td>
                    <div
                      style={{
                        backgroundColor: autoSettleFor.find(
                          (item) =>
                            item.toLowerCase() ==
                            market?.gameType?.toLowerCase()
                        )
                          ? "green"
                          : "",
                      }}
                      className="d-flex justify-content-between align-items-center "
                    >
                      {market.marketName}

                      {market.marketName === "Who Will Win Toss" && (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleOpenOddsDetailsModal(market)}
                        >
                          View Odds
                        </button>
                      )}
                    </div>
                  </td>
                  <td
                    title="Edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOpenEditBetModal(market.id)}
                  >
                    {market.minStack} - {market.maxStack}
                  </td>
                  <td>
                    {" "}
                    <Form.Check
                      type="switch"
                      label={market.betLock ? "Bet Disabled" : "Bet Enabled"}
                      checked={market.betLock}
                      onChange={(e) =>
                        handleSwitchChange(e, market, "Market_BetStatus")
                      }
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="switch"
                      label={market.enabled ? "Enable" : "Disable"}
                      onChange={(e) =>
                        handleSwitchChange(e, market, "Market_Enable")
                      }
                      checked={market.enabled}
                    />
                  </td>
                  <td>
                    {isEmpty(market.marketSettled) || !market.marketSettled ? (
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          handleOpenMarketSettelModal(
                            market,
                            market.cricketEventId,
                            "settle"
                          )
                        }
                      >
                        Settle
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-danger"
                        onClick={() =>
                          handleOpenMarketSettelModal(
                            market,
                            market.cricketEventId,
                            "rollback"
                          )
                        }
                      >
                        Roll Back
                      </button>
                    )}
                  </td>
                </tr>
              ))
            : allMarketData?.inactive?.map((market) => (
                <tr key={market.id}>
                  <td>
                    <div
                      style={{
                        backgroundColor: autoSettleFor.find(
                          (item) =>
                            item.toLowerCase() ==
                            market?.gameType?.toLowerCase()
                        )
                          ? "green"
                          : "",
                      }}
                      className="d-flex justify-content-between align-items-center "
                    >
                      {market.marketName}

                      {market.marketName === "Who Will Win Toss" && (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleOpenOddsDetailsModal(market)}
                        >
                          View Odds
                        </button>
                      )}
                    </div>
                  </td>
                  <td
                    title="Edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOpenEditBetModal(market.id)}
                  >
                    {market.minStack} - {market.maxStack}
                  </td>
                  <td>
                    {" "}
                    <Form.Check
                      type="switch"
                      label={market.betLock ? "Bet Disabled" : "Bet Enabled"}
                      checked={market.betLock}
                      onChange={(e) =>
                        handleSwitchChange(e, market, "Market_BetStatus")
                      }
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="switch"
                      label={market.enabled ? "Enable" : "Disable"}
                      onChange={(e) =>
                        handleSwitchChange(e, market, "Market_Enable")
                      }
                      checked={market.enabled}
                    />
                  </td>
                  <td>
                    {isEmpty(market.marketSettled) || !market.marketSettled ? (
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          handleOpenMarketSettelModal(
                            market,
                            market.cricketEventId,
                            "settle"
                          )
                        }
                      >
                        Settle
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-danger"
                        onClick={() =>
                          handleOpenMarketSettelModal(
                            market,
                            market.cricketEventId,
                            "rollback"
                          )
                        }
                      >
                        Roll Back
                      </button>
                    )}
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
      <Pagination
        activePage={activePage}
        itemsCountPerPage={20}
        totalItemsCount={totalPageCount}
        pageRangeDisplayed={20}
        onChange={handlePageChange}
      />

      <ConfirmModal
        show={showConfrimMatchModal}
        handleClose={handleCloseConfirmMatchModal}
        handleConfirm={matchEnableDisabledHandler}
      >
        <h5>
          Are you sure you want to{" "}
          <strong>{confirmMatchDetails?.confirmValue}</strong> this{" "}
          <strong>{confirmMatchDetails?.matchName}</strong>?
        </h5>
      </ConfirmModal>
      <CustomModal
        show={showEditBetModal}
        handleClose={handleCloseEditBetModal}
        onSubmit={onSubmitBetHandler}
        title="Edit Match Bet"
        isError={errorMessage}
      >
        <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Min Bet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter min bet value"
              name="minMarket"
              value={editBetData?.minMarket}
              onChange={handleMarketEditChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Max Bet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter max bet value"
              name="maxMarket"
              value={editBetData?.maxMarket}
              onChange={handleMarketEditChange}
            />
          </Form.Group>
        </Form>
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
      </CustomModal>
      <CustomModal
        show={showMarketSettelModal}
        handleClose={handleCloseMarketSettelModal}
        onSubmit={settleMarketFancyHandler}
        title="Market Settelment"
        isError={errorMessage}
        settelmentData={settelmentData}
        fromSettel
        isRollback={marketSettlmentType === "rollback"}
      >
        {marketSettlmentType !== "rollback" ? (
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              {settelmentData?.marketName
                ?.toLowerCase()
                .includes(marketNameWhoWin.toLowerCase()) ||
              settelmentData?.marketName == "BOOKMAKER_ODDS_1" ||
              settelmentData?.marketName == "BOOKMAKER_ODDS_2" ? (
                <FloatingLabel controlId="floatingSelect" label="Select Winner">
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={handleChangeWinner}
                  >
                    <option>Please select a winner</option>
                    {settelmentData?.subMarketInfo?.map((item) => (
                      <option
                        value={`${item.selectionId}-${item.runnerName}`}
                        key={item.selectionId}
                      >
                        {item.runnerName}
                      </option>
                    ))}
                    {settelmentData?.marketName
                      ?.toLowerCase()
                      .includes(marketNameWhoWin.toLowerCase()) ? (
                      <>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </>
                    ) : (
                      <option value="abandoned">Abandoned</option>
                    )}
                    {!settelmentData?.marketName
                      ?.toLowerCase()
                      .includes(marketNameWhoWin.toLowerCase()) && (
                      <option value="tie">Tie</option>
                    )}
                  </Form.Select>
                </FloatingLabel>
              ) : (
                // <Form.Check
                //   type="switch"
                //   id="custom-switch"
                //   label={"Session Passed"}
                //   onChange={(e) => handleSwitchSettelChange(e)}
                // />
                <>
                  <Form.Check
                    inline
                    label="Abandoned"
                    name="matchResultType"
                    type="radio"
                    id={`inline-radio-1`}
                    value={matchResultType?.abandoned}
                    onChange={(e) => handleChangeRadio(e, "abandoned")}
                  />
                  <Form.Check
                    inline
                    label="Tie"
                    name="matchResultType"
                    type="radio"
                    id={`inline-radio-2`}
                    value={matchResultType?.tie}
                    onChange={(e) => handleChangeRadio(e, "tie")}
                  />

                  <Form.Check
                    inline
                    label="Final Result"
                    name="matchResultType"
                    type="radio"
                    id={`inline-radio-3`}
                    value={matchResultType?.finalResult}
                    onChange={(e) => handleChangeRadio(e, "finalResult")}
                  />

                  {matchResultType?.finalResult &&
                    (settelmentData?.requiresNumberForSettlement == null ||
                      settelmentData?.requiresNumberForSettlement == true) && (
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Final Result</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter final result"
                          name="finalResult"
                          value={finalResult}
                          onChange={handleChangeFinalResult}
                        />
                      </Form.Group>
                    )}
                  {matchResultType?.finalResult &&
                    settelmentData?.requiresNumberForSettlement == false && (
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Final Result</Form.Label>
                        <FormSelect
                          type="select"
                          placeholder="Enter final result"
                          name="finalResult"
                          value={finalResult}
                          onChange={handleChangeFinalResult}
                        >
                          <option value="YES">Yes</option>
                          <option value="NO">No</option>
                          {/* <FormSelect.Option value={"YES"}>
                            YES
                          </FormSelect.Option>
                          <FormSelect.Option value={"NO"}>NO</FormSelect.Option> */}
                        </FormSelect>
                      </Form.Group>
                    )}
                </>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter comment"
                name="comment"
                value={settelmentData?.comment}
                onChange={handleChangeSettel}
              />
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Form>
        ) : (
          <h5>Are you sure you want to rollback</h5>
        )}
      </CustomModal>

      <CustomModal
        show={showOddsDetails}
        handleClose={handleCloseOddsDetailsModal}
        onSubmit={onSubmitBetHandler}
        title="Odds Details"
        isError={errorMessage}
        noFooter
      >
        {oddsDetails?.subMarketInfo?.map((item) => (
          <div>
            <h5>
              {item.runnerName}{" "}
              <span
                style={{ cursor: "pointer" }}
                className="d-inline-block ml-3"
                onClick={() => editOddsHandler(item.id)}
              >
                <FaPen />
              </span>
            </h5>
            <hr />
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Row>
                <Col className="d-flex align-items-center">
                  <Form.Label className="mb-0 mt-4">Odds</Form.Label>
                </Col>
                <Col>
                  <Form.Label className="text-center d-block">
                    Back 1
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="back 1"
                    name="back1"
                    defaultValue={item?.runnerOdds?.back1}
                    onChange={(e) =>
                      handleChangeOddsDetails(e, "back1", item.id)
                    }
                    disabled={item.isEdit}
                  />
                </Col>
                <Col>
                  <Form.Label className="text-center d-block">
                    Back 2
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="back 2"
                    name="back2"
                    value={item?.runnerOdds?.back2}
                    onChange={(e) =>
                      handleChangeOddsDetails(e, "back2", item.id)
                    }
                    disabled={item.isEdit}
                  />
                </Col>
                <Col>
                  <Form.Label className="text-center d-block">
                    Back 3
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="back 3"
                    name="back3"
                    value={item?.runnerOdds?.back3}
                    onChange={(e) =>
                      handleChangeOddsDetails(e, "back3", item.id)
                    }
                    disabled={item.isEdit}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex align-items-center">
                  <Form.Label className="mb-0">Volume</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Volume"
                    name="volume"
                    value={item?.runnerOdds?.backVolume1}
                    onChange={(e) =>
                      handleChangeOddsDetails(e, "backVolume1", item.id)
                    }
                    disabled={item.isEdit}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Volume"
                    name="back2"
                    value={item?.runnerOdds?.backVolume2}
                    onChange={(e) =>
                      handleChangeOddsDetails(e, "backVolume2", item.id)
                    }
                    disabled={item.isEdit}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Volume"
                    name="back3"
                    value={item?.runnerOdds?.backVolume3}
                    onChange={(e) =>
                      handleChangeOddsDetails(e, "backVolume3", item.id)
                    }
                    disabled={item.isEdit}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Row>
                <Col className="d-flex align-items-center">
                  <Form.Label className="mb-0 mt-4">Odds</Form.Label>
                </Col>
                <Col>
                  <Form.Label className="text-center d-block">Lay 1</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="lay 1"
                    name="lay1"
                    value={item?.runnerOdds?.lay1}
                    onChange={(e) =>
                      handleChangeOddsDetails(e, "lay1", item.id)
                    }
                    disabled={item.isEdit}
                  />
                </Col>
                <Col>
                  <Form.Label className="text-center d-block">Lay 2</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="lay 2"
                    name="lay2"
                    value={item?.runnerOdds?.lay2}
                    onChange={(e) =>
                      handleChangeOddsDetails(e, "lay2", item.id)
                    }
                    disabled={item.isEdit}
                  />
                </Col>
                <Col>
                  <Form.Label className="text-center d-block">Lay 3</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="lay 3"
                    name="lay3"
                    value={item?.runnerOdds?.lay3}
                    onChange={(e) =>
                      handleChangeOddsDetails(e, "lay3", item.id)
                    }
                    disabled={item.isEdit}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex align-items-center">
                  <Form.Label className="mb-0">Volume</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Volume"
                    name="volume"
                    value={item?.runnerOdds?.layVolume1}
                    onChange={(e) =>
                      handleChangeOddsDetails(e, "layVolume1", item.id)
                    }
                    disabled={item.isEdit}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Volume"
                    name="back2"
                    value={item?.runnerOdds?.layVolume2}
                    onChange={(e) =>
                      handleChangeOddsDetails(e, "layVolume2", item.id)
                    }
                    disabled={item.isEdit}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Volume"
                    name="back3"
                    value={item?.runnerOdds?.layVolume3}
                    onChange={(e) =>
                      handleChangeOddsDetails(e, "layVolume3", item.id)
                    }
                    disabled={item.isEdit}
                  />
                </Col>
              </Row>
            </Form.Group>

            {!item.isEdit && (
              <div className="d-flex justify-content-end">
                <button
                  onClick={() => onSubmitEditOddsHandler(item.id)}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        ))}
      </CustomModal>

      <ToastMessage
        show={showToast}
        close={handleCloseToast}
        message={toastMessage}
        type="Success"
        title=""
      />
    </>
  );
}
