/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  IoCalendarSharp,
  IoCashSharp,
  IoLogOutSharp,
  IoMenuOutline,
  IoSettings,
} from "react-icons/io5";
import { useHistory } from "react-router-dom";

export default function Sidebar({ sidebar }) {
  let history = useHistory();
  const logoutHandler = () => {
    localStorage.clear();
    history.push("/");
  };
  return (
    <div id="sidebar" className="active">
      <div className="sidebar-wrapper active">
        <div className="sidebar-header">
          <div className="d-flex justify-content-between">
            <div className="logo">
              <a href="/">Controller</a>
            </div>
            {sidebar && sidebar}
            <div className="toggler">
              <a href="#" className="sidebar-hide d-xl-none d-block">
                <i className="bi bi-x bi-middle"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="sidebar-menu">
          <ul className="menu">
            <li className="sidebar-title">Menu</li>
            <li className="sidebar-item  ">
              <a
                activeClassName="active"
                href="/dashboard/event-management/cricket"
                className="sidebar-link"
              >
                <IoCalendarSharp></IoCalendarSharp>
                <span>Event Management</span>
              </a>
            </li>
            <li className="sidebar-item  ">
              <a
                activeClassName="active"
                href="/dashboard/series-management"
                className="sidebar-link"
              >
                <IoCashSharp></IoCashSharp>
                <span>Series Management</span>
              </a>
            </li>
            <li className="sidebar-item  ">
              <a
                activeClassName="active"
                href="/dashboard/settings"
                className="sidebar-link"
              >
                <IoSettings />
                <span>Settings</span>
              </a>
            </li>
            <li className="sidebar-item  ">
              <a
                activeClassName="active"
                href="/dashboard/banner-management"
                className="sidebar-link"
              >
                <IoSettings />
                <span>Banner Management</span>
              </a>
            </li>
            <li className="sidebar-item  ">
              <a
                activeClassName="active"
                href="/dashboard/upcoming-fixture"
                className="sidebar-link"
              >
                <IoSettings />
                <span>UpComing Fixture</span>
              </a>
            </li>
            <li className="sidebar-item  ">
              <a
                activeClassName="active"
                href="/dashboard/announcements"
                className="sidebar-link"
              >
                <IoSettings />
                <span>Announcements</span>
              </a>
            </li>
            <li className="sidebar-item  ">
              <a
                href="javascript:void(0)"
                className="sidebar-link"
                onClick={logoutHandler}
              >
                <IoLogOutSharp></IoLogOutSharp>
                <span>Logout</span>
              </a>
            </li>
          </ul>
        </div>
        <button className="sidebar-toggler btn x">
          <i data-feather="x"></i>
        </button>
      </div>
    </div>
  );
}
